@import 'bootstrap/bootstrap.scss';
@import 'fontawesome/fontawesome.scss';
@import 'daterangepicker/daterangepicker.scss';

body {
  background: rgb(246, 249, 252);
}
.carousel-indicators .active {
    border: 1px solid rgb(0, 123, 255) !important;
}
.d-block.w-50.mx-auto {
    max-height: 400px;
    width: auto !important;
}
.border-dashed {
  border-style: dashed !important;
  border-width: .2rem !important;
}
#dropzone {
   cursor: pointer;
}
.dropdown-menu .dropdown-item {
  white-space: normal !important;
}
.dropdown-menu .dropdown-item img {
  width: 24px;
  height: 24px;
}
.dropdown-menu-right {
  width: 400px;
}
.pointer {
  cursor: pointer;
}
.move {
  cursor: move;
}
.minHeight600 {
  min-height: 600px;
}
.box-shadow {
  box-shadow: 0 6px 10px rgba(36,37,38,0.06);
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.daterangepicker {
    width: 100%;
    max-width: 270px;
    box-shadow: 0px 0px 4px 1px rgb(168, 168, 168);
    display: none;
}
.drp-selected {
    display: none;
}
.applyBtn {
    float: right;
    margin-right: 7px;
}
.cancelBtn {
    float: right;
}
.uploadedImg {
  max-height: 500px;
  width: auto;
}
.prev:before {
    content: "\f053";
    font-family: Font Awesome 5 Pro;
    font-weight: 900;
}
.next:before {
    content: "\f054";
    font-family: Font Awesome 5 Pro;
    font-weight: 900;
}
.box-shadow:hover {
  box-shadow: 5px 12px 20px rgba(36,37,38,0.13);
}
.toastClub {
  display: none;
  width: 260px;
  background: #2E3C54;
  color: #fff;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99999999999;
  padding: 15px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: rgb(0, 123, 255) !important;
  background-color: inherit !important;
  border: 1px solid rgb(0, 123, 255);
}
.biggerLineHeight {
  line-height: 2.3rem;
}
.under-link {
  text-decoration: underline;
}
.half-opacity {
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -moz-opacity: 0.50; 
  -khtml-opacity: 0.50; 
  opacity: .5;
  transition: .5s all;
}
.half-opacity:hover {
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1; 
  -khtml-opacity: 1; 
  opacity: 1;
}



@include media-breakpoint-up(xs) {
html {
    font-size: 1.1rem;
  }
  .bigger-1 {
  font-size: 1.2rem !important;
  }
  .bigger-2 {
    font-size: 1.4rem !important;
  }
  .bigger-3 {
    font-size: 1.6rem !important;
  }
  .bigger-4 {
    font-size: 1.8rem !important;
  }
  .bigger-5 {
    font-size: 2rem !important;
  }
  .smaller-1 {
    font-size: 1rem !important;
  }
  .smaller-2 {
    font-size: 1rem !important;
  }
  .smaller-3 {
    font-size: 1rem !important;
  }
  .smaller-4 {
    font-size: 1rem !important;
  }
  .smaller-5 {
    font-size: .5rem !important;
  }


}


@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
  .bigger-1 {
  font-size: 1.2rem !important;
  }
  .bigger-2 {
    font-size: 1.4rem !important;
  }
  .bigger-3 {
    font-size: 1.6rem !important;
  }
  .bigger-4 {
    font-size: 1.8rem !important;
  }
  .bigger-5 {
    font-size: 2rem !important;
  }
  .smaller-1 {
    font-size: 1rem !important;
  }
  .smaller-2 {
    font-size: 1rem !important;
  }
  .smaller-3 {
    font-size: 1rem !important;
  }
  .smaller-4 {
    font-size: 1rem !important;
  }
  .smaller-5 {
    font-size: .5rem !important;
  }

}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.2rem;
  }
  .bigger-1 {
    font-size: 1rem !important;
  }
  .bigger-2 {
    font-size: 1.2rem !important;
  }
  .bigger-3 {
    font-size: 1.4rem !important;
  }
  .bigger-4 {
    font-size: 1.5rem !important;
  }
  .bigger-5 {
    font-size: 1.6rem !important;
  }
  .smaller-1 {
    font-size: 1rem !important;
  }
  .smaller-2 {
    font-size: 1rem !important;
  }
  .smaller-3 {
    font-size: 1rem !important;
  }
  .smaller-4 {
    font-size: 1rem !important;
  }
  .smaller-5 {
    font-size: .5rem !important;
  }


}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
  .bigger-1 {
  font-size: 1.2rem !important;
  }
  .bigger-2 {
    font-size: 1.4rem !important;
  }
  .bigger-3 {
    font-size: 1.6rem !important;
  }
  .bigger-4 {
    font-size: 1.8rem !important;
  }
  .bigger-5 {
    font-size: 2rem !important;
  }
  .smaller-1 {
    font-size: 1rem !important;
  }
  .smaller-2 {
    font-size: .9rem !important;
  }
  .smaller-3 {
    font-size: .8rem !important;
  }
  .smaller-4 {
    font-size: .7rem !important;
  }
  .smaller-5 {
    font-size: .6rem !important;
  }


}